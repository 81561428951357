import { observer } from "mobx-react-lite"
import * as React from "react"
import InputField from "../components/common/InputField"
import TitleWithSeparator from "../components/common/TitleWithSeparator"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navigation/Navbar"
import { images } from "../data/variables"
import { store } from "../translations/store"
import "./contact.scss"

const Contact = observer(() => {
  const [subject, setSubject] = React.useState<string>("")
  const [name, setName] = React.useState<string>("")
  const [message, setMessage] = React.useState<string>("")
  const [email, setEmail] = React.useState<string>("")
  React.useEffect(() => {
    store.setInitialLoad()
  }, [])
  return (
    <div className="contact-page">
      <Navbar />
      <div className="contact-form">
        <div className="inner w-90">
          <h1 className="page-name">Kontakt</h1>
          <div className="form">
            <div className="head">
              <TitleWithSeparator text="STUPITE U KONTAKT" />
              <p
                dangerouslySetInnerHTML={{
                  __html: `Popunite e-mail kontakt formu i izložite nam vaša pitanja! Mi ćemo se potruditi da Vam odgovorimo u najskorijem roku! <br/> <b>EVROTOM DOO</b>`,
                }}
              />
            </div>
            <div className="fields">
              <div className="inner-section">
                <div className="d-flex upper">
                  <InputField
                    value={name}
                    onChange={(v: string) => setName(v)}
                    type="text"
                    placeholder="Ime i prezime"
                  />
                  <InputField
                    value={subject}
                    onChange={(v: string) => setSubject(v)}
                    type="text"
                    placeholder="Naslov"
                  />
                </div>
                <div className="d-flex mt-2 bottom">
                  <div>
                    <InputField
                      value={email}
                      onChange={(v: string) => setEmail(v)}
                      type="text"
                      placeholder="Email adresa"
                    />
                    <button className="button">POŠALJI</button>
                  </div>
                  <div>
                    <textarea
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder="Vasa poruka"
                      rows={4}
                    />
                  </div>
                </div>
                <div className='mobile-form d-flex flex-column'>
                  <InputField
                      value={name}
                      onChange={(v: string) => setName(v)}
                      type="text"
                      placeholder="Ime i prezime"
                  />
                  <InputField
                      value={email}
                      onChange={(v: string) => setEmail(v)}
                      type="text"
                      placeholder="Email adresa"
                  />
                  <InputField
                    value={subject}
                    onChange={(v: string) => setSubject(v)}
                    type="text"
                    placeholder="Naslov"
                  />
                  <textarea
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder="Vasa poruka"
                      rows={4}
                  />
                  <button className="button">POŠALJI</button>
                </div>
              </div>
            </div>
            <div className="information">
              <div className="inner-section d-flex">
                <div className="left d-flex">
                  <div>
                    <h4>Proizvodnja i sedište kompanije</h4>
                    <div className="d-flex company">
                      <div>
                        <span>
                          <b>Direktor:</b> Toma Pavlić <br />
                          <b>Adresa:</b> Kraljevačka 46, <br />
                          22400 Ruma, Srbija.
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>Tel:</b> +381(0)22-479-569 <br />
                          <b>Fax:</b> +381(0)22-471-675, <br />
                          <a
                            className="mailto"
                            href="mailto:evrotom@hotmail.com"
                          >
                            evrotom@hotmail.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <h4>Radno vreme</h4>
                  <div>
                    <span>
                      Pon – Pet: 8am to 4pm <br />
                      Sub : 8am to 1pm
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="offices">
        <div className="inner-section d-flex flex-column w-90">
          <h4>Predstaništva</h4>
          <div className="list d-flex">
            <Office
              name="Hrvatska"
              flags={[images.croatia]}
              description={`<b>Kontakt:</b> Silvio Cvitković <br/>
                            H.V. Hrvatinića 38, 32100 <br/> Vinkovci, Hrvatska <br/>
                            <b>Tel:</b> +385 32 307 014 <br/>
                            <b>Mob:</b> +385 98 933 77 74 <br/>
                            <a class='mailto' href='mailto:euroapishr@hotmail.com'>euroapishr@hotmail.com</a>`}
            />
            <Office
              name="Makedonija"
              flags={[images.macedonia]}
              description={`<b>Kontakt:</b> Aneta Stojkova <br/>
                            lindenska , Čaška <br/> Makedonija <br/>
                            <b>Tel:</b> +389 71 338 450 <br/>
                            <a class='mailto' href='mailto:evrotommk@gmail.com'>evrotommk@gmail.com</a> <br/>
                            <a href='https://www.evrotom.mk'>www.evrotom.mk</a>`}
            />
            <Office
              name="Bugarska"
              flags={[images.bulgaria]}
              description={`<b>Kontakt:</b> Viktoria Moneva <br/>
                            Boul. Vladimir Vazov 25, <br/> Sklad 3, 1517 Sofia, Bulgaria <br/>
                            <b>Tel:</b> +359 29 950 280 <br/>
                            <b>Fax:</b> +359 28 284 395 <br/>
                            <a class='mailto' href='mailto:evrotom@abv.bg'>evrotom@abv.bg</a>`}
            />
            <Office
              name="Ukrajina"
              flags={[images.ukraine]}
              description={`<b>Menadžer:</b> Pastuh Svetlana <br/>
                            Kadetskij Gaj 6, Kiev, <br/> Ukraine <br/>
                            <b>Tel:</b> +380 50 525 88 91 <br/> +380 95 138 30 46 <br/>
                            <b>Fax:</b> +380 44 270 25 83 <br/>
                            <a class='mailto' href='mailto:ceca@ukr.net'>ceca@ukr.net</a>`}
            />
            <Office
              name="BALTIČKE DRŽAVE (LETONIJA,LITVANIJA, ESTONIJA)"
              flags={[images.letonia, images.lithuania, images.estonia]}
              description={`<b>Kontakt:</b> Janis Raubens <br/>
                            <b>Firma:</b> SIA Bišu Saime <br/>
                            Garozas iela 54, Jelgava, <br/> Letonija <br/>
                            <b>Tel:</b> +371 26 855 247 <br/> +371 29 58 18546 <br/>
                            <b>Facebook/ Instagram:</b> Bišu saime <br/>
                            <a class='mailto' href='mailto:info@bisusaime.lv'>info@bisusaime.lv</a> <br/>
                            <a href='https://www.bisusaime.lv'>www.bisusaime.lv</a>`}
            />
            <Office
              name="SKANDINAVSKE DRŽAVE (ŠVEDSKA, NORVEŠKA, DANSKA)"
              flags={[images.sweden, images.norway, images.denmark]}
              description={`<b>Kontakt:</b> Haris Sokolovic <br/>
                            Önsvalavägen 54, <br/> Staffanstorp, Švedska <br/>
                            <b>Tel:</b> +467 07 828 555 <br/>
                            <a class='mailto' href='mailto:www.biredskapscentralen.se'>www.biredskapscentralen.se</a> <br/>`}
            />
          </div>
          <div className="list d-flex">
            <Office
              name="SVLOVAČKA I ČEŠKA"
              flags={[images.slovakia, images.czechia]}
              description={`<b>Kontakt:</b> Božík Branislav <br/>
                            Priepasne, Slovakia <br/>
                            <b>Tel:</b> +421905069003 <br/>
                            <a class='mailto' href='mailto:objednavky@vcelieule-bozik.sk'>objednavky@vcelieule-bozik.sk</a> <br/>
                            <a href='https://vcelieule-bozik.sk'>vcelieule-bozik.sk</a> <br/>
                            <a href='https://vcelarstvi-bozik.cz'>vcelarstvi-bozik.cz</a>`}
            />
          </div>
        </div>
      </div>
      <div className="location">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              height="428"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Kraljevacka%2046%20ruma&t=&z=13&ie=UTF8&iwloc=&output=embed"
              scrolling="no"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
})

interface OfficeProps {
  flags: string[]
  name: string
  description: string
}
const Office: React.FC<OfficeProps> = ({ flags, name, description }) => {
  return (
    <div className="office">
      <div className="flags">
        {flags.map((f, i) => (
          <img key={i} src={f} />
        ))}
      </div>
      <h6 className="name">{name}</h6>
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}
export default Contact
